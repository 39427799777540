<template>
    <div class="download-float-wrap" v-if="show">
        <div class="download-left">
            <img class="logo" src="~/assets/img/common/logo-pro.png" />
            <Gap :width="8" />
            <div class="lf-text">
                <h3 class="h3-txt">WEEX App</h3>
                <div class="info">{{ $t('index.download_slogan1') }}</div>
            </div>
        </div>
        <div class="download-right">
            <div class="download-btn" @click="goDwonload">{{ $t('newFooter.download') }}</div>
            <Gap :width="12"/>
            <div class="close-wrap" @click="show = false">
                <img class="close-icon" src="~/assets/img/common/close-x.png" />
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { getDeviceInfo } from '~/utils/utils.js';
import Gap from '~/components/gap/index.vue';
export default {
    components: {
      Gap,
    },
    head() {
      return {
        htmlAttrs: {
          class: 'weex-index-wrap'
        }
      }
    },
    data() {
        return {
            show: true
        }
    },
    computed: {
        ...mapState(['locale', 'newTheme']),
    },
    mounted() {
    },
    methods: {
        goDwonload() {
            /* const deviceType = getDeviceInfo();
            let query = {}
            if (deviceType == 'Windows' || deviceType == 'Android') {
                query.androidApk = 'Y'
            } else if (deviceType == 'MacOS' || deviceType == 'IOS') {
                query.iosAPP = 'Y'
            } */
            this.$router.push({ path: this.$i18n.path('download')});
        }
    }
};
</script>
<style lang='less' scoped>
.download-float-wrap {
    display: none;
}

@media screen and (max-width: 1020px) {

}

@media screen and (max-width: 1019px) {
    .download-float-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 1000000;
        width: 100%;
        box-sizing: border-box;
        padding: 13px 12px;
        background: @black-border-subtle-V2;

        .download-left {
            display: flex;
            align-items: center;
            flex:1;
            .logo {
                width: 38px;
                height: 38px;
                // margin-right: 8px;
            }

            .lf-text {
                font-size: 14px;

                .h3-txt {
                    .bold-font();
                }

                .info {
                    color: @black-text-tertiary;
                }
            }

        }

        .download-right {
            display: flex;
            align-items: center;

            .download-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 80px;
                height: 28px;
                padding: 8px;
                box-sizing: border-box;
                border-radius: 8px;
                font-size: 12px;
                .medium-font();
                cursor: pointer;
                background-color: @yellow-P1-new;
                color: #000;
                // margin-right: 12px;
            }

            .close-wrap {
                .close-icon {
                    width: 14px;
                    height: 14px;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
